<template>
  <logo />

  <div class="float-right text-title py-0 mt-[10px] font-medium">
    <div class="flex items-center">
      <div v-if="currentUser" class="relative">
        <div
          class="cursor-pointer content-text flex items-center"
          @click="showLoginInfo = !showLoginInfo"
        >
          <span class="">
            {{ currentUser.firstName || currentUser.email }}
          </span>
          <span class="material-icons"> arrow_drop_down </span>
        </div>

        <div
          v-if="showLoginInfo"
          class="absolute top-full right-0 mt-1 py-2 w-40 rounded-lg shadow text-sm leading-6 font-semibold bg-neutral-900 text-slate-200 highlight-white/5"
          aria-labelledby="headlessui-menu-button-6"
          id="headlessui-menu-items-7"
          role="menu"
          tabindex="0"
        >
          <template v-if="!isGuest">
            <a
              href="javascript:void(0)"
              class="block px-3 py-1 hover:bg-neutral-800"
              @click.prevent="$router.push({ name: 'accountHome' })"
              >Profile</a
            >
            <a
              href="javascript:void(0)"
              class="block px-3 py-1 hover:bg-neutral-800"
              @click.prevent="$router.push({ name: 'accountTicket' })"
              >My N3 Pass</a
            >
          </template>
          <template v-else>
            <a class="block px-3 py-1 hover:bg-neutral-800 opacity-50">
              {{ currentUser.email }}</a
            >
          </template>
          <a
            href="javascript:void(0)"
            class="block px-3 py-1 hover:bg-neutral-800"
            @click.prevent="onLogout()"
            >Sign out</a
          >
        </div>
      </div>
      <div v-else>
        <a
          href="/auth/login"
          @click.prevent="$router.push({ name: 'login' })"
          class="font-bold"
          >SIGN IN</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import Logo from "./Logo.vue";
export default {
  components: { Logo },
  name: "HeaderSection",
  setup() {
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      showLoginInfo: false,
      isGuest: computed(() => store.state.auth.isGuest),
      currentUser: computed(() => store.state.auth.currentUser),
    });
    watch(
      () => proxy.$route,
      () => {
        state.showLoginInfo = false;
      }
    );
    function onLogout() {
      store.dispatch("auth/signOut");
      proxy.$router.push({ name: "home" });
    }
    return {
      ...toRefs(state),
      onLogout,
    };
  },
};
</script>
<style lang="scss">
.logo {
  width: 80px;
  height: 80px;
  // background: transparent linear-gradient(131deg, #212121 8%, #000000 100%) 0%
  //   0% no-repeat padding-box;
  opacity: 1;
  font-size: 3rem;
  color: #fff;
}
</style>
