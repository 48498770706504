import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import CryptoJS from "crypto-js";

export function makeUniqueID(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toLocaleUpperCase();
}

export function saveStorage(key, items) {
  localStorage.setItem(key, JSON.stringify(items));
}

export function removeStorage(key) {
  const storage = localStorage.getItem(key);
  if (storage !== null) {
    localStorage.removeItem(key);
  }
}

export function toDecimal(val) {
  if (!val) {
    return 0;
  }
  if (typeof val !== "string") {
    return parseFloat(val);
  }
  return parseFloat(val.replace(",", ""));
}
export function sumByCol(object, col = "name") {
  return _.sumBy(object, col);
}

export function orderBy(object, col = [], dir = []) {
  return _.orderBy(object, col, dir);
}

export function dateTimeFormat(
  date,
  format = "DD.MM.YYYY HH:mm",
  timezone = null
) {
  if (!date) return "-";
  const d = new Date(date);
  let momentInstance = moment(d);
  if (timezone) {
    momentInstance = moment(d).tz(timezone);
  }

  return momentInstance.format(format, "de");
}

export function currentDateTime(format = "DD.MM.YYYY HH:mm") {
  return moment().format(format, "de");
}

export function convertToUTCString(timestamp) {
  return new Date(timestamp.toDate()).toUTCString();
}

export function seatNameShorter(label) {
  return label;
  // const labelArray = label.split(" | ");
  // const newLabel = [];
  // labelArray.forEach((element) => {
  //   const a = element.split(" ");
  //   if (a[1]) {
  //     newLabel.push(a[1]);
  //   } else {
  //     newLabel.push(a[0]);
  //   }
  // });
  // return newLabel.join(" | ");
}

export function moneyFormat(amount, currency = "EUR", local = "de-DE") {
  amount = toDecimal(amount);
  return new Intl.NumberFormat(local, {
    style: "currency",
    currency,
  }).format(amount);
}

export function dineroFormat(dineroObject) {
  if (dineroObject.getAmount) {
    return dineroObject.toFormat("$0.00");
  }
  return 0;
}

export function getStorage(key, key2 = null) {
  const items = localStorage.getItem(key);
  if (items !== null) {
    const j = JSON.parse(items);
    return key2 ? j[key2] : j;
  }
  return null;
}

export function mapJson(data, keys = []) {
  const newJson = [];
  keys.forEach((key) => {
    newJson[key] = data[key];
  });
  return newJson;
}

export function cloneCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function clearBrowserCache() {
  localStorage.clear();
  if ("serviceWorker" in navigator) {
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames.map(function (cacheName) {
          return caches.delete(cacheName);
        })
      );
    });
  }
}

export function addZero(number, len) {
  return String(number).padStart(len, "0");
}

export function scrollToTop() {
  window.scrollTo({ top: 0 });
}

export function encrypt(text) {
  const cipherText = CryptoJS.AES.encrypt(text, process.env.VUE_APP_KEY);

  return cipherText
    .toString()
    .replace(/\+/g, "p1L2u3S")
    .replace(/\//g, "s1L2a3S4h")
    .replace(/=/g, "e1Q2u3A4l");
}

export function decrypt(cipherText) {
  const text = cipherText
    .replace(/p1L2u3S/g, "+")
    .replace(/s1L2a3S4h/g, "/")
    .replace(/e1Q2u3A4l/g, "=");

  return CryptoJS.AES.decrypt(text, process.env.VUE_APP_KEY).toString(
    CryptoJS.enc.Utf8
  );
}
